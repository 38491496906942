<script>
export default {
  name: 'CourseEnrollModal',
  components: {
    Loading: () => import('@/components/general/Loading')
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    course: {
      type: Object,
      required: true
    }
  },
  computed: {
    descriptionErrorText () {
      return this.showProfileRedirect ? 'modal.course.error.description.phone' : 'modal.course.error.description'
    },
    errorButtonText () {
      return this.course.enrolled ? 'modal.course.error.access' : 'modal.course.error.enroll'
    }
  },
  data () {
    return {
      isLoading: false,
      requestFailed: false,
      showProfileRedirect: false
    }
  },
  watch: {
    show (newValue) {
      if (newValue && this.course.enrolled) this.handleEnroll()
    }
  },
  methods: {
    RedirectToProfilePage () {
      this.$router.push({
        name: 'settings.profile.form',
        params: {
          fullPath: this.$route.fullPath,
          workspaceAvatar: this.selectedWorkspace.avatar,
          workspaceType: this.selectedWorkspace.type
        }
      })
      this.$emit('close-modals')
    },
    handleEnroll () {
      this.isLoading = true
      this.$store.dispatch('attemptEnrollInCourse', this.course.id)
        .then((data) => {
          window.open(this.fixedCourseUrl(data.urlRedirect), '_blank')
          this.closeModal()
        })
        .catch((error) => {
          this.isLoading = false
          this.requestFailed = true
          if (error.response.data.error === 'Register your phone!') this.showProfileRedirect = true
        })
    },
    closeModal () {
      this.requestFailed = false
      this.isLoading = false
      this.showProfileRedirect = false
      this.$emit('cancel')
    }
  }
}
</script>
<template>
  <v-dialog
    :value="show"
    :key="show"
    content-class="dialog-enroll--modal"
    persistent
    max-width="600px"
    height="80%"
  >
    <v-card>
      <v-card-title class="titleContent h7">
      </v-card-title>
      <span class="dialog-enroll--modal_subtitle subtitle--1" ></span>
      <span class="top-right" v-if="!isLoading">
        <v-btn icon @click="closeModal()">
          <v-icon :color="getPrimaryColor">mdi-close</v-icon>
        </v-btn>
      </span>
      <div v-if="isLoading">
        <Loading/>
      </div>
      <div v-if="!isLoading && !requestFailed">
        <h5 class="dialog-enroll--modal_title">
          {{ $t('modal.course.enroll.title', { courseName: course.title }) }}
        </h5>
        <v-card-text class="dialog-enroll--modal_subtitle">
          {{ $t('modal.course.enroll.description', { providerName: course.provider.name }) }}
        </v-card-text>
        <ul>
          <li>{{ $t('modal.course.enroll.options.name') }}</li>
          <li>{{ $t('modal.course.enroll.options.email') }}</li>
          <li>{{ $t('modal.course.enroll.options.phone') }}</li>
        </ul>
        <div class="dialog-enroll--actions">
          <v-btn
            dark
            :color="getPrimaryColor"
            @click="closeModal"
            outlined
            class="text-secondary btn transform-unset mr-2"
          >
            {{ $t('global:cancel') }}
          </v-btn>
          <v-btn
            dark
            class="btn transform-unset"
            :color="getPrimaryColor"
            @click="handleEnroll"
          >
            {{ $t('global:confirm') }}
          </v-btn>
        </div>
      </div>
      <div v-if="!isLoading && requestFailed">
        <h5 class="dialog-enroll--modal_title ">
          {{ $t(errorButtonText) }}
        </h5>
         <v-card-text class="dialog-enroll--modal_subtitle">
          {{ $t(descriptionErrorText) }}
        </v-card-text>
        <a v-if="showProfileRedirect" @click="RedirectToProfilePage" class="dialog-enroll--modal_subtitle link-style">{{$t('modal.course.error.redirect.profile')}}</a>
        <div class="dialog-enroll--error_button_wrapper">
        <v-btn
            dark
            class="btn transform-unset"
            :color="getPrimaryColor"
            @click="closeModal"> {{ $t('global:close') }} </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.dialog-enroll--modal {
  font-family: $lato;
  text-align: left;
  .v-sheet {
    padding: 24px;
  }
  .v-card__text {
    text-align: left;
    font-family: $lato;
    font-size: 16px;
    line-height: 19px;
    margin-top: 20px;
  }
  .v-card__actions .v-btn__content {
    font-weight: bold;
  }
  .v-card .v-card__text {
    padding: 0;
  }
  .v-card__title.titleContent {
    color: $neutral-dark;
    font-weight: bolder;
    padding: 0;
    margin-bottom: 25px;
  }
  .dialog-enroll--actions {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    .v-btn {
      font-weight: bold;
    }
  }
  .dialog-enroll--modal_title {
    font-size: 20px;
    margin-bottom: 36px;
  }
  .dialog-enroll--modal_subtitle {
    margin-bottom: 15px;
  }
  .dialog-enroll--error_button_wrapper {
    display: flex;
    justify-content: center !important;;
    align-items: center;
    margin-top: 40px;
  }
  .link-style {
    text-decoration: underline;
  }
}
.spaced-slot {
  margin-top: 40px;
}
</style>
